import { API } from "aws-amplify"

export const restAuth = (method, path, body) =>
  body
    ? API[method]("herveAuthApi", path, {
        body,
      })
    : API[method]("herveAuthApi", path)

export const restUnauth = (method, path, body) =>
  body
    ? API[method]("herveOpenApi", path, {
        body,
      })
    : API[method]("herveOpenApi", path)
