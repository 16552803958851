import React from "react"
import { useDispatch } from "react-redux"
import { navigate } from "gatsby"
import { Avatar, makeStyles, Typography } from "@material-ui/core"
import CheckIcon from "@material-ui/icons/Check"
import WarningIcon from "@material-ui/icons/Warning"
import LoadingBackdrop from "../components/LoadingBackDrop"
import { restUnauth } from "../services/rest"
import { useLocation } from "@reach/router"
import { fetchUserData } from "../components/AuthListener"
import { useSelector } from "react-redux"
import { selectCognitoUser } from "../app/cognitoUser"

const queryString = require("query-string")

const errorMessages = {
  "confirmation time exceeded": `Désolé, le délai de confirmation a été dépassé.\n\nVous allez être redirigé vers la page de votre commerçant`,
}

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100vh",
    flex: 1,
    background: theme.palette.primary.main,
    alignItems: "center",
    justifyContent: "space-around",
    padding: 20,
  },
  icon: { ...theme.typography.h1, color: "green" },
}))

export default function AuthCallback() {
  const location = useLocation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const cognitoUser = useSelector(selectCognitoUser)
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState()
  const { code, state } = queryString.parse(location.search)
  console.log({ code, state, loc: location.search })

  React.useEffect(() => {
    if (cognitoUser && state && code) {
      restUnauth("get", `/open/authurlcb${location.search}`)
        .then(response => {
          console.log({ response })
          if (response.success) {
            fetchUserData(cognitoUser, dispatch)
            setTimeout(() => {
              navigate("/dashboard/calendar?refresh=true")
            }, 5000)
          } else {
            setError("La connection du calendrier a échoué")
          }
          setLoading(false)
        })
        .catch(err => {
          setError(err)
          setLoading(false)
          console.warn("failed to store token")
        })
    }
  }, [cognitoUser, code, state, dispatch])
  return (
    <div className={classes.root}>
      <LoadingBackdrop open={loading} />
      {!loading && (
        <div className={classes.root}>
          <Avatar
            style={{ background: "white", width: "20vw", height: "20vw" }}
          >
            {error ? (
              <WarningIcon className={classes.icon} style={{ color: "red" }} />
            ) : (
              <CheckIcon className={classes.icon} />
            )}
          </Avatar>
          <Typography
            variant="h4"
            style={{
              color: "white",
              textAlign: "center",
              whiteSpace: "pre-wrap",
            }}
          >
            {error
              ? errorMessages[error] || error.message || error
              : "Votre calendrier est bien connecté !"}
          </Typography>
          {!error && (
            <Typography
              variant="h6"
              style={{ color: "white", textAlign: "center" }}
            >
              Vous allez être redirigé vers votre espace personnel
            </Typography>
          )}
        </div>
      )}
    </div>
  )
}
